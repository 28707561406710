<template>
  <div>

  </div>
</template>

<script>
  export default {
    name: "SelectName",

    data() {
      return {
      }
    },

    props: {
      name: String
    },

    computed: {

      getUserName() {
        return localStorage.getItem('nameUser');
      },

    },
  }
</script>

<style scoped lang="scss">


  @import "../../assets/scss/utils/vars";

  .form__input {
    border: none;
    border-bottom: 2px solid $navy;
    padding-right: 160px;
    padding-left: 0;
  }

  .btn {

    &:disabled {
      opacity: 0.7;
      cursor: default;
      &:hover {
        border-color: $navy;
      }
    }

  }

</style>
