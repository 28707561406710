<template>
  <div class="container">
    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

      <breadcrumbs v-if="!isEditMode"></breadcrumbs>

    </section>
    <section class="sizes">

      <div class="row">
        <div class="col-12" v-if="createdOrder">
          <div class="section-title">
            <h2 class="titlePage titlePage_subtitle">ENTER SIZE</h2>
          </div>
        </div>

        <div v-if="createdOrder" class="col-md-6 form-section">
          <Form v-slot="{ errors }" action="" class="form" autocomplete="off">
            <label class="form__label form__label_row" :class="{width: isWidth}">
              <span class="form__labelTitle">Width</span>
              <Field type="text" class="form__input"
                     v-model="width"
                     name="width"
                     :rules="{required: true, min_value: minAllowedWidth, max_value: maxAllowedWidth}"
              />
              <span class="form__labelTitle">mm</span>
              <span class="form__error">{{ errors.width }}</span>
            </label>
            <label class="form__label form__label_row" :class="{drop: !isWidth}">
              <span class="form__labelTitle">Drop</span>
              <Field type="text" class="form__input"
                     name="drop"
                     v-model="drop"
                     :rules="{required: true, min_value:minAllowedDrop, max_value: maxAllowedDrop}"
              />
              <span class="form__labelTitle">mm</span>
              <span class="form__error">{{ errors.drop }}</span>
            </label>
          </Form>
        </div>

        <!--<select-name v-else :name="namePackage" class="col-md-6"></select-name>-->
        <div v-else class="col-md-6">
          <label v-if="!isSet" class="form__label">
            <input type="text" class="form__input"
                   name="name"
                   placeholder="Type Package Name"
                   v-model="name"
            >
          </label>
          <label v-else class="form__label">
            <input type="text" class="form__input"
                   name="set_name"
                   placeholder="Type Set Name"
                   v-model="name"
            >
          </label>
          <label v-show="showCrateForAll"
                 class="form__label form__label_row"
                 v-if="getUserRole !== 'admin'"
          >
            <input type="checkbox" hidden class="form__checkbox"
                   v-model="allUsers"
                   :checked="allUsers"
            >
            <span class="form__checkbox_icon"></span>
            <span class="form__labelTitle">Create {{isSet ? 'set' : 'package'}} for all users under my account</span>
          </label>
        </div>
      </div>

      <div v-if="lockTypes" class="row">
        <div class="col-12">
          <button type="button" class="btn btn_default" @click="goToOptions"><svg-icon name="solid/step-forward" /> next</button>
        </div>
      </div>

    </section>
    <section class="item-types">
      <h2 class="titlePage_subtitle titlePage">SELECT TYPE <span class="selectedText">{{currentPosition.item.type_name}}</span></h2>
      <div class="row" :class="{locked: lockTypes}" :disabled="lockTypes">
        <div class="col-md-2 col-sm-4 col-6 type" :class="{'active': currentPosition.item.type_name === type.name }"
             v-for="type in types"
             :key="type.id"
             :disabled="type.disabled"
             @click="selectType(type)"
        >
          <div class="box" :class="{'box_opacity': type.disabled}">
            <p class="box__imgWrap">
              <img :src="type.icon" :alt="type.name" class="box__img">
            </p>
            <p class="text_default box__title">{{type.name}}</p>
            <p class="text_red text_small" v-if="type.disabled && type.name">(restricted by size)</p>
          </div>
        </div>
      </div>
    </section>
    <template v-if="actionLinksPortalTarget && isEditMode">
      <portal :to="actionLinksPortalTarget">
        <button type="button" class="btn btn_transparent" @click="next"><svg-icon name="solid/step-forward" /> next</button>
      </portal>
    </template>
  </div>
</template>

<script>

  import router from "../../router";
  import {httpReq} from "../../htttpReq";
  import breadcrumbs from "./../parts/breadcrumbs";
  import SelectName from '../../components/orders/SelectName';
  import createdOrder from "../../store/modules/order/createdOrder";


  import {mapGetters, mapState, mapMutations} from 'vuex';


  export default {
    name: "SelectType",
    data() {
      return {
        width: '',
        drop: '',
        maxAllowedWidth: 0,
        maxAllowedDrop: 0,
        minAllowedWidth: 60000,
        minAllowedDrop: 60000,
        name: '',
        allUsers: false
      };
    },

    props: {createdOrder: Boolean, isSet: Boolean, actionLinksPortalTarget: String},

    components: {
      breadcrumbs, SelectName
    },

    methods: {
      ...mapMutations('app', ['setParentParams', 'set']),
      //...mapMutations('createdOrder', ['updateWidth', 'updateDrop']),
      ...mapMutations('orderPosition', ['setWidth', 'setDrop']),
      ...mapMutations('options', ['setTypeOrigin', 'setAllOptions', 'setItemId']),
      goToOptions() {
       /* this.$validator.validate().then(valid => {
          if (!valid) {
            return false;
          }*/
          this.setWidth(_.toInteger(this.width));
          this.setDrop(_.toInteger(this.drop));
          this.setItemId(this.parentParams.itemId);
          this.setTypeOrigin(null);
          this.setAllOptions(true);
          let breadcrumbs = this.prevBreadcrumbs;
          breadcrumbs[1].name = this.width + ' x ' + this.drop;
          this.set({k: 'breadcrumbs', v: breadcrumbs});
          this.$router.push(this.parentParams.nextPath);
          this.setParentParams({});
       /* });*/
      },
      next() {
       /* this.$validator.validate().then(valid => {*/
          const type = this.types.find(t => t.name === this.currentPosition.item.type_name)

          if( type.disabled) {
            this.$store.commit('errorsModal/openModal', {0: ['The dimensions does not support the selected Type.']});
            return false
          }

          let typeSetting = {
            id: this.currentPosition.item.type_id,
            name: this.currentPosition.item.type_name
          }
          this.$store.dispatch('orderPosition/updateType', {width: this.width, drop: this.drop, type: typeSetting});
      /*  })*/
      },
      selectType(target) {
        if (this.lockTypes || target.disabled) {
          return false
        }

      /*  this.$validator.validate().then(valid => {*/
            if (this.width !== '' && this.drop !== '') {
                if(this.isEditMode) {
                  this.$store.dispatch('orderPosition/updateType', {width: this.width, drop: this.drop, type: target});
                }else {
                  this.$store.commit('orderPosition/setTypeAndSize', {width: this.width, drop: this.drop, type: target});
                }
            }
            else {
                this.$store.commit('errorsModal/openModal', {0: ['Please enter width and drop']});
                return false
            }
       /* });*/


        // this.$store.commit('createdOrder/updateType', target.id);
        // this.$store.commit('createdOrder/editingModals', target.id);

        // this.$parent.exchangeData = 'selectStyle';

//        this.$store.commit('types/setOSQR', {width: this.width, drop: this.drop});
//         this.$store.commit('createdOrder/updateWidth', this.width);
//         this.$store.commit('createdOrder/updateDrop', this.drop);

        // this.$store.commit('createdOrder/updateName', this.name);
        // this.$store.commit('createdOrder/updateForAllUsers', this.allUsers);

        // let path = this.$store.getters['app/orderBuilderPath']('type_id>' + target.id);

        // if (this.createdOrder) {
        //   this.$validator.validate().then(valid => {
        //     if (valid && this.width !== '' && this.drop !== '') {
        //       router.push({path: path});
        //       this.$store.dispatch(
        //         'app/addBreadcrumb',
        //         {
        //           route: {
        //             path: path
        //           },
        //           name: `(${this.width} x ${this.drop}) ${target.name}`,
        //         }
        //       );
        //     }
        //     else {
        //       this.$store.commit('errorsModal/openModal', {0: ['Please enter width and drop']});
        //     }
        //   });
        //
        // } else {
        //   if (this.name !== '') {
        //     router.push({path: path});
        //     this.$store.commit(
        //       'app/set',
        //       {
        //         k: 'breadcrumbs',
        //         v: [{
        //           route: {
        //             path: this.isSet ? '/sets-builder' : '/package-builder'
        //           },
        //           name: this.name,
        //         }]
        //       }
        //     );
        //     this.$store.dispatch(
        //       'app/addBreadcrumb',
        //       {
        //         route: {
        //           path: path
        //         },
        //         name: target.name,
        //       }
        //     );
        //   } else {
        //     this.$store.commit('errorsModal/openModal', {0: ['You must enter name']});
        //   }
        // }
      },
      setAllowedWidthAndDrop() {
        _.each(this.types, (type) => {
          if (!type.validation || type.validation.length === 0) {
            return;
          }
          let maxDrop = _.maxBy(type.validation, 'drop_max');
          if (maxDrop && this.maxAllowedDrop < maxDrop.drop_max) {
            this.maxAllowedDrop = maxDrop.drop_max;
          }
          let maxWidth = _.maxBy(type.validation, 'width_max');
          if (maxWidth && this.maxAllowedWidth < maxWidth.width_max) {
            this.maxAllowedWidth = maxWidth.width_max;
          }
          let minDrop = _.minBy(type.validation, 'drop_min');
          if (minDrop && this.minAllowedDrop > minDrop.drop_min) {
            this.minAllowedDrop = minDrop.drop_min;
          }
          let minWidth = _.minBy(type.validation, 'width_min');
          if (this.minAllowedWidth > minWidth.width_min) {
            this.minAllowedWidth = minWidth.width_min;
          }
        });
      },
      setDisabledTypes() {
        _.each(this.types, (type, key) => {
          // Remove disabled if width or drop not set.
          if (!this.width || !this.drop) {
            this.types[key].disabled = false;
            return;
          }
          if (typeof type.validation === 'undefined') {
            this.types[key].disabled = true;
            return;
          }
          this.types[key].disabled = this.isTypeItemNotInRange(this.width, this.drop, type);
        });
      },

      isTypeItemNotInRange (width, drop, item) {
        let result = true;
        _.each(item.validation, function (rule) {
            if ((drop <= rule.drop_max && drop >= rule.drop_min)
              && (width <= rule.width_max && width >= rule.width_min)) {
              result = false;
            }
        });

        return result;
      },
    },

    computed: {
      ...mapState('app', ['parentParams', 'prevBreadcrumbs']),
      ...mapState('orderItem', ['isUpdate']),
      ...mapState('orderPosition', ['currentPosition', 'isCompleted']),
      isEditMode() {
        return this.isCompleted
      },

      ...mapGetters({
          isWidth: 'orderPreview/isWidth',
          positionName: 'orderPreview/name'
      }),

        ...mapGetters({
            currentStep: 'orderPosition/currentStep'
        }),

      getUserName() {
        return localStorage.getItem('nameUser');
      },
      user() {
        return this.$store.getters['userList/user'];
      },
      types() {
        return this.$store.getters['types/types'];
      },
      orderData() {
        return this.$store.getters['orderPosition/orderPosition'];
      },
      getMultipleAccount() {
        return this.$store.getters['users/multipleAccount'];
      },
      getUserRole() {
        return this.$store.getters['users/userRole'];
      },
      showCrateForAll() {
        return this.getUserRole !== 'customer' && this.getMultipleAccount === 1;
      },
      lockTypes() {
        return _.has(this.parentParams, 'goToOptions')
      }
    },
    watch: {
      width() {
        this.setDisabledTypes()
      },
      drop() {
        this.setDisabledTypes()
      },
      types() {
        this.setDisabledTypes()
      },
      orderData(n) {

        if (this.name && this.name.length < 1) {
          this.name = n.name
        }
        if (this.name && this.width.length < 1) {
          this.width = n.wdt
        }
        if (this.name && this.drop.length < 1) {
          this.drop = n.htl
        }
      }
    },
    created() {

      this.$store.commit('types/update', false);

      if (this.name.length < 1) {
        this.name = this.orderData.name
      }
      if (this.width.length < 1) {
        this.width = this.orderData.wdt
      }
      if (this.drop.length < 1) {
        this.drop = this.orderData.htl
      }

      this.setAllowedWidthAndDrop();
      this.debouncedDisableItems = _.debounce(this.setDisabledTypes, 400);

      //this.checkRequestUrl();
    },
      mounted(){
        this.$store.dispatch('types/getTypes');
      }
    // unmounted(){
    //   let order = this.$store.getters['orderPosition/orderPosition'];
    //
    //   if(order.options['OSQR']){
    //
    //     let width = parseFloat(order.items.options['OSQR'].settings.length1);
    //     let drop = parseFloat(order.items.options['OSQR'].settings.length4);
    //
    //     if(this.width && this.drop){
    //       if(this.width !== width || this.drop !== drop){
    //         this.$store.commit('types/update', true);
    //       }
    //     }
    //   }
     //}
  }
</script>

<style scoped lang="scss">
  @import '../../../src/assets/scss/utils/vars';

  .type.active {
    .box__title {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .selectedText {
    font-size: 1rem;
    color: $gray;
  }
  .form {
    display: flex;
    flex-direction: column;

    &__label {
      margin-bottom: 30px;
      order: 2;
      position: relative;
    }

    &__labelTitle {
      color: $green;
      margin-right: 15px;
      font-size: 20px;
      line-height: 20px;
      min-width: 60px;
    }
    &__input {
      margin-right: 30px;
      border: none;
      border-bottom: 2px solid #0b3553;
      &:after {
        content: 'mm';
      }
    }
    .item-types {
      margin-bottom: 2rem;
    }

    .drop {
      order: 1;
    }
    .width {
      order: 1;
    }
  }

  .box {
    margin-bottom: 1rem;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: 160px;
      max-height: 160px;
    }
    p {
      text-align: center;
    }
  }

  .form__error {
    position: absolute;
    bottom: -20px;
    left: 0
  }


</style>
